import { createReducer, on } from '@ngrx/store';

import { createEmptyInvitationFilter } from '@celum/sacc/domain';

import { invitationActions } from './invitation.actions';
import { initialInvitationState, userInvitationAdapter } from './invitation.state';

export const invitationReducer = createReducer(
  initialInvitationState,
  on(invitationActions.fetchBatch, state => ({ ...state, loading: true })),
  on(invitationActions.fetchBatchFailure, state => ({ ...state, loading: false })),
  on(invitationActions.fetchBatchSuccess, (state, { batch }) => ({
    ...userInvitationAdapter.upsertMany(batch.entities, state),
    continuationToken: batch.continuationToken,
    totalCount: batch.totalCount,
    filterCount: batch.filterCount,
    loading: false
  })),
  on(invitationActions.search, (state, action) => ({
    ...state,
    loading: true,
    continuationToken: state.continuationToken,
    filter: action.filter
  })),
  on(invitationActions.filterChanged, (state, action) => ({
    ...userInvitationAdapter.removeAll(state),
    loading: true,
    continuationToken: '',
    filter: action.filter
  })),
  on(invitationActions.searchFailure, state => ({ ...state, loading: false })),
  on(invitationActions.searchSuccess, (state, { batch }) => {
    return {
      ...userInvitationAdapter.upsertMany(batch.entities, state),
      continuationToken: batch.continuationToken,
      totalCount: batch.totalCount,
      filterCount: batch.filterCount,
      loading: false
    };
  }),
  on(invitationActions.deleteInvitationSuccess, (state, { id }) =>
    userInvitationAdapter.removeOne(id, {
      ...state,
      totalCount: state.totalCount - 1,
      filterCount: state.filterCount - 1
    })
  ),
  on(invitationActions.resendInvitationSuccess, (state, { invitation }) => userInvitationAdapter.upsertOne(invitation, state)),
  on(invitationActions.sortChanged, (state, { sort }) => ({
    ...state,
    sort,
    continuationToken: null
  })),
  on(invitationActions.inviteSuccess, (state, { invitations }) => userInvitationAdapter.upsertMany(invitations, state)),
  on(invitationActions.resetAccountTable, state => ({ ...initialInvitationState, filter: state.filter })),
  on(invitationActions.resetAccountTableFilter, state => ({ ...state, filter: createEmptyInvitationFilter() })),
  on(invitationActions.disapproveAccountAccessSuccess, (state, { invitation }) => userInvitationAdapter.upsertOne(invitation, state))
);
