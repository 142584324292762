import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule as MatCheckBoxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumButtonModule, CelumIconModule, CelumLookupAreaModule, ColorConstants, IconConfiguration, LookupArea } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';
import { createEmptyUserGroupFilter, UserGroupFilters } from '@celum/sacc/domain';
import { ClickablePopupDialogComponent } from '@celum/shared/ui';

import { UserGroupFilterService } from './user-group-filter.service';

@Component({
  selector: 'sacc-user-group-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCheckBoxModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,

    CelumButtonModule,
    CelumIconModule,
    CelumLookupAreaModule,
    CelumPipesModule,

    ClickablePopupDialogComponent
  ],
  templateUrl: './user-group-filter.component.html',
  styleUrl: './user-group-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [UserGroupFilterService]
})
export class UserGroupFilterComponent {
  @HostBinding('class.sacc-user-group-filter') protected readonly hostClass = true;

  @Output() public readonly filterChanged = new EventEmitter<UserGroupFilters>();

  @ViewChild('firstSearchInput', { static: false, read: LookupArea }) protected searchInput: LookupArea;

  protected readonly icons = {
    filter: IconConfiguration.small('filter-l').withColor(ColorConstants.SYSTEM_BLACK),
    search: IconConfiguration.medium('search-m').withColor(ColorConstants.BLUE_GRAY_900),
    clear: IconConfiguration.medium('cancel-m').withColor(ColorConstants.BLUE_GRAY_900)
  };

  constructor(protected service: UserGroupFilterService) {}

  @Input() public set filter(userGroupFilters: UserGroupFilters) {
    this.service.setFilter(userGroupFilters);
  }

  protected clearAllFilters(): void {
    // Clear only the filters, leave sorting as it is!
    this.filterChanged.emit(createEmptyUserGroupFilter(this.service.currentFilter.sort));
  }

  protected onMenuOpenedChanged(opened: boolean): void {
    if (opened) {
      this.searchInput.focus();
    }
  }

  protected groupNameSearchChanged(groupName: string): void {
    if (this.service.currentFilter.name !== groupName) {
      this.filterChanged.emit({ ...this.service.currentFilter, name: groupName });
    }
  }

  protected importedChanged(): void {
    this.filterChanged.emit({ ...this.service.currentFilter, imported: !this.service.currentFilter.imported });
  }

  protected onKeyDown(keyboardEvent: KeyboardEvent) {
    if (keyboardEvent.key === 'Tab' || (keyboardEvent.key === 'Tab' && keyboardEvent.shiftKey)) {
      keyboardEvent.stopPropagation();
    }
  }
}
