<button
  class="sacc-icon-text-button sacc-icon-text-button--{{ size }} sacc-icon-text-button--w-{{ width }} sacc-icon-text-button--{{ color }}"
  [class.sacc-icon-text-button--disabled]="disabled"
  [disabled]="disabled"
  [type]="type"
  mat-flat-button
  (click)="clicked.emit($event)">
  <span class="sacc-icon-text-button_content">
    @if (iconConfiguration) {
      <celum-icon [configuration]="iconConfiguration"></celum-icon>
    }
    {{ label | translate }}
  </span>
</button>
