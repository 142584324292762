@if (service.vm$ | async; as vm) {
  <ng-container class="sacc-user-groups-list" data-test-sacc-user-table>
    <div class="sacc-user-groups-list_header">
      <sacc-table-header
        [count]="vm.userGroupsCountFiltered"
        [countLabelPlural]="'COMPONENTS.USER_GROUPS.LIST.COUNTS'"
        [countLabelSingular]="'COMPONENTS.USER_GROUPS.LIST.COUNT'">
        <sacc-user-group-filter-chips
          ngProjectAs="advanced-filter-chips"
          [filter]="vm.filter"
          (filterChanged)="service.filterChanged($event)"></sacc-user-group-filter-chips>
        <sacc-user-group-filter ngProjectAs="advanced-filter" [filter]="vm.filter" (filterChanged)="service.filterChanged($event)"></sacc-user-group-filter>
      </sacc-table-header>
    </div>

    @if (vm.userGroupsCount >= maxGroupCount) {
      <message-box
        class="sacc-user-groups-list_limit-reached"
        [text]="'COMPONENTS.USER_GROUPS.LIST.LIMIT_REACHED' | translate: { groupLimit: maxGroupCount }"
        [type]="'warn'"></message-box>
    }

    @if (vm.userGroupsCount > 0) {
      <table
        aria-label="User groups table"
        class="sacc-user-groups-list_table"
        infiniteScroll
        matSort
        mat-table
        [dataSource]="vm.userGroups"
        [fromRoot]="true"
        [infiniteScrollContainer]="scrollContainer"
        [trackBy]="userGroupTracker"
        (matSortChange)="sortData($event)"
        (scrolled)="!vm.allLoaded && !vm.loading && service.loadNextBatch()">
        <ng-container matColumnDef="groupAvatar">
          <th *matHeaderCellDef mat-header-cell>&nbsp;</th>
          <td *matCellDef="let element; let i = index" mat-cell>
            <sacc-group-avatar [config]="element | groupToAvatarConfig"></sacc-group-avatar>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="name">{{ 'COMPONENTS.USER_GROUPS.LIST.HEADERS.NAME' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="imported">
          <th *matHeaderCellDef="let element" id="imported-header" mat-header-cell mat-sort-header="imported">
            {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.IMPORTED' | translate }}
          </th>
          <td *matCellDef="let element" mat-cell [ngClass]="element.status">
            @if (element.imported) {
              <celum-icon [configuration]="icons.imported" [matTooltip]="'COMPONENTS.USER_GROUPS.LIST.IMPORTED_TOOLTIP' | translate"></celum-icon>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef id="actions-header" mat-header-cell>&nbsp;</th>
          <td *matCellDef="let element" mat-cell>
            @if (vm.activeAccountAccess?.role === AccountUserRole.MANAGER && !vm.isReadonly) {
              <sacc-icon-button
                [iconConfiguration]="icons.menu"
                [matMenuTriggerFor]="menu"
                [matTooltip]="'COMPONENTS.TABLE.TOOLTIPS.MORE_ACTIONS' | translate"
                (click)="$event.stopPropagation()"></sacc-icon-button>
            }
            <mat-menu #menu="matMenu">
              <ng-container>
                <button
                  [disabled]="element.imported"
                  [matTooltip]="'COMPONENTS.USER_GROUPS.LIST.IMPORTED_DELETE_DISABLED_TOOLTIP' | translate"
                  [matTooltipDisabled]="!element.imported"
                  mat-menu-item
                  (click)="deleteGroup(element)">
                  <celum-icon [configuration]="icons.delete"></celum-icon>
                  <span>{{ 'COMPONENTS.USER_GROUPS.LIST.DELETE_GROUP' | translate }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="vm.displayedColumns; sticky: true" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: vm.displayedColumns; let index = index"
          class="sacc-user-groups-list_table-row"
          mat-row
          (click)="
            openGroupDialog(
              row,
              vm.activeAccountAccess,
              vm.activeAccountAccess?.role !== AccountUserRole.MANAGER || vm.isReadonly || row.imported,
              vm.accountId
            )
          "></tr>
      </table>
    }

    @if (!vm.userGroupsCountFiltered && !vm.loading) {
      <empty-page class="sacc-user-groups-list_no-results-found" [config]="noResultsConfig"></empty-page>
    }

    @if (vm.loading) {
      <div class="sacc-user-groups-list_loading">
        <mat-spinner [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
      </div>
    }
  </ng-container>
}
