import { Sort } from '@angular/material/sort';

import { ProvisioningType } from '@celum/authentication';

import { FederationType } from './federation';
import { SortablePaginationOption } from '../sortable-pagination-option';

export interface FederationFilters extends SortablePaginationOption {
  name?: string;
  domainHint?: string;
  federationType?: FederationType[];
  provisioningType?: ProvisioningType[];
}

export function createEmptyFederationFilter(sort?: Sort): FederationFilters {
  return {
    name: '',
    domainHint: '',
    sort: sort || createDefaultFederationFilterSort()
  };
}

export function createDefaultFederationFilterSort(): Sort {
  return { active: 'createdOn', direction: 'asc' };
}
