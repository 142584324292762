<clickable-popup-dialog [enableHoverColorOnOpenedMenu]="false" [contentClass]="'sacc-filter_popup'" (menuOpenedChanged)="onMenuOpenedChanged($event)">
  <div popupTrigger>
    <button celum-button class="sacc-filter_trigger" data-test-sacc-invitation-filter-button mat-flat-button size="small">
      <div class="sacc-filter_trigger-content">
        {{ 'COMMON.FILTER' | translate }}
        <celum-icon [configuration]="icons.filter"></celum-icon>
      </div>
    </button>
  </div>
  <div popupContent (click)="$event.stopPropagation()">
    <div class="sacc-filter_menu" data-test-sacc-invitation-filter-menu (keydown)="onKeyDown($event)">
      @if (service.vm$ | async; as vm) {
        <!-- Member name search -->
        <div class="sacc-filter_menu-section">
          <p class="sacc-filter_menu-section-header sacc-filter_menu-section-header-text">
            {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.MEMBER_NAME.TITLE' | translate }}
          </p>

          <lookup-area
            #firstSearchInput
            class="sacc-filter_menu-search-input"
            data-test-sacc-invitation-filter-menu-search-member-name-input
            tabindex="0"
            [debounceTime]="300"
            [formFieldSize]="'small'"
            [placeholder]="'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.MEMBER_NAME.PLACEHOLDER' | translate"
            [value]="vm.filter.memberName"
            (searchValue)="memberNameSearchChanged($event)"></lookup-area>
        </div>

        <hr />

        <!-- Status checkboxes -->
        @if (filterSections.get('COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE'); as statusFilterSectionValues) {
          <div class="sacc-filter_menu-checkbox-section sacc-filter_menu-checkbox-section-bottom">
            <p class="sacc-filter_menu-checkbox-section-header sacc-filter_menu-checkbox-section-header-text">
              {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE' | translate }}
            </p>
            <button
              *ngFor="let item of statusFilterSectionValues"
              class="sacc-filter_menu-checkbox-button"
              mat-button
              [disableRipple]="true"
              (click)="toggleFilter(item.value)">
              <mat-checkbox
                [attr.data-test-sacc-invitation-filter-menu-status-checkbox]="item.value"
                [checked]="item | wrapFn: isFilterApplied : vm.filter"
                [disableRipple]="true"
                [labelPosition]="'before'"
                (change)="toggleFilter(item.value)"
                (click)="$event.stopPropagation()">
                <sacc-invitation-status [invitationStatus]="$any(item).value"></sacc-invitation-status>
              </mat-checkbox>
            </button>
          </div>
        }

        <hr />

        <!-- Groups search list -->
        <div class="sacc-filter_menu-checkbox-section sacc-filter_menu-checkbox-section-top">
          <sacc-filter-group-search
            [accountId]="vm.accountId"
            [selectedItems]="vm.filter.groups"
            [title]="'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.GROUPS.TITLE'"
            (selectedItemsChanged)="onSelectedGroupItemsChanged($event)"></sacc-filter-group-search>
        </div>

        <hr />

        <button
          class="sacc-filter_menu-clear-all-btn"
          data-test-sacc-invitation-filter-menu-clear-all-button
          tabindex="4"
          type="button"
          [disabled]="vm.noFiltersApplied"
          (click)="clearAllFilters()">
          {{ 'SHARED.CLEAR_ALL' | translate }}
        </button>
      }
    </div>
  </div>
</clickable-popup-dialog>
