import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule as MatCheckBoxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { InvitationStatus } from '@celum/authentication';
import { CelumButtonModule, CelumIconModule, CelumLookupAreaModule, ColorConstants, IconConfiguration, LookupArea } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';
import { createEmptyInvitationFilter, InvitationFilters, SelectedGroupItem } from '@celum/sacc/domain';
import { ClickablePopupDialogComponent } from '@celum/shared/ui';

import { InvitationFilterItem, invitationFilterItemByValue } from './invitation-filter.item';
import { InvitationFilterService } from './invitation-filter.service';
import { FilterGroupSearchComponent } from '../../filter-group-search/filter-group-search.component';
import { InvitationStatusComponent } from '../../invitation-status/invitation-status.component';

@Component({
  selector: 'sacc-invitation-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCheckBoxModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,

    CelumButtonModule,
    CelumIconModule,
    CelumLookupAreaModule,
    CelumPipesModule,

    ClickablePopupDialogComponent,
    FilterGroupSearchComponent,
    InvitationStatusComponent
  ],
  templateUrl: './invitation-filter.component.html',
  styleUrl: './invitation-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [InvitationFilterService]
})
export class InvitationFilterComponent {
  @HostBinding('class.sacc-invitation-filter') protected readonly hostClass = true;

  @Output() public readonly filterChanged = new EventEmitter<InvitationFilters>();

  @ViewChild('firstSearchInput', { static: false, read: LookupArea }) protected searchInput: LookupArea;

  protected readonly filterSections: Map<string, InvitationFilterItem[]> = new Map([
    [
      'COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
      [
        invitationFilterItemByValue[InvitationStatus.INVITED],
        invitationFilterItemByValue[InvitationStatus.REJECTED],
        invitationFilterItemByValue[InvitationStatus.PENDING_APPROVAL],
        invitationFilterItemByValue[InvitationStatus.DISAPPROVED]
      ]
    ]
  ]);

  protected readonly icons = {
    filter: IconConfiguration.small('filter-l').withColor(ColorConstants.SYSTEM_BLACK),
    search: IconConfiguration.medium('search-m').withColor(ColorConstants.BLUE_GRAY_900),
    clear: IconConfiguration.medium('cancel-m').withColor(ColorConstants.BLUE_GRAY_900)
  };

  constructor(protected service: InvitationFilterService) {}

  @Input() public set filter(invitationFilter: InvitationFilters) {
    this.service.setFilter(invitationFilter);
  }

  protected clearAllFilters(): void {
    // Clear only the filters, leave sorting as it is!
    this.filterChanged.emit(createEmptyInvitationFilter(this.service.currentFilter.sort));
  }

  protected onMenuOpenedChanged(opened: boolean): void {
    if (opened) {
      this.searchInput.focus();
    }
  }

  protected memberNameSearchChanged(memberName: string): void {
    if (this.service.currentFilter.memberName !== memberName) {
      this.filterChanged.emit({ ...this.service.currentFilter, memberName });
    }
  }

  protected isFilterApplied(filter: InvitationFilterItem, filters: InvitationFilters): boolean {
    return filters.status?.includes(filter.value);
  }

  public toggleFilter(value: InvitationStatus): void {
    const previousValue = this.service.currentFilter.status;

    let nextValue = [value];
    if (previousValue) {
      nextValue = previousValue.includes(value) ? previousValue.filter(a => a !== value) : [...previousValue, value];
    }

    this.filterChanged.emit({ ...this.service.currentFilter, status: nextValue });
  }

  protected onKeyDown(keyboardEvent: KeyboardEvent) {
    if (keyboardEvent.key === 'Tab' || (keyboardEvent.key === 'Tab' && keyboardEvent.shiftKey)) {
      keyboardEvent.stopPropagation();
    }
  }

  protected onSelectedGroupItemsChanged(selectedGroupItems: SelectedGroupItem[]) {
    this.filterChanged.emit({ ...this.service.currentFilter, groups: selectedGroupItems });
  }
}
