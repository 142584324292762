import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CelumLookupAreaModule } from '@celum/common-components';

// TODO: this component is not really needed, it can be replaced by the basic lookup component everywhere
@Component({
  selector: 'sacc-search-bar',
  templateUrl: './search-bar.component.html',
  imports: [CelumLookupAreaModule]
})
export class SearchBarComponent {
  @Input()
  public placeholder: string;
  @Input()
  public searchString: string;
  @Output()
  public readonly closeEvent = new EventEmitter<void>();
  @Output()
  public readonly changeEvent = new EventEmitter<string>();

  public clear(): void {
    this.closeEvent.emit();
  }

  public searchChanged(event: any): void {
    this.changeEvent.emit(event);
  }
}
