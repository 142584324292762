import { Sort } from '@angular/material/sort';

import { SortablePaginationOption } from '../sortable-pagination-option';

export enum AccountFilterLicenseType {
  WORK = 'WORK',
  EXPERIENCE = 'EXPERIENCE',
  DRIVE = 'DRIVE',
  CONTENT = 'CONTENT_HUB'
}

export interface AccountFilters extends SortablePaginationOption {
  orgId?: string;
  orgName?: string;
  owner?: string;
  createdOnFrom?: Date;
  createdOnTo?: Date;
  activeLicenses?: AccountFilterLicenseType[];
}

export function createEmptyAccountFilter(sort?: Sort): AccountFilters {
  return {
    orgId: '',
    orgName: '',
    owner: '',
    createdOnFrom: undefined,
    createdOnTo: undefined,
    sort: sort || createDefaultAccountFilterSort()
  };
}

export function createDefaultAccountFilterSort(): Sort {
  return { active: 'createdOn', direction: 'asc' };
}
