import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

import { DataUtil } from '@celum/core';
import { createEmptyUserGroupFilter, UserGroupFilters } from '@celum/sacc/domain';
import { AppState, selectAccountActiveAccountId } from '@celum/sacc/shared';

export type UserGroupFilterState = {
  filter: UserGroupFilters;
};

export type UserGroupFilterViewModel = {
  noFiltersApplied: boolean;
  accountId?: string;
} & UserGroupFilterState;

@Injectable()
export class UserGroupFilterService extends ComponentStore<UserGroupFilterState> {
  public vm$ = this.select(this.state$, this.store$.select(selectAccountActiveAccountId), (state, accountId) => this.createViewModel(state, accountId));

  constructor(private store$: Store<AppState>) {
    super({ filter: createEmptyUserGroupFilter() });
  }

  private createViewModel(state: UserGroupFilterState, accountId: string): UserGroupFilterViewModel {
    return {
      ...state,
      noFiltersApplied: this.noFilterApplied(state.filter),
      accountId
    };
  }

  public setFilter(filter: UserGroupFilters) {
    this.patchState({ filter });
  }

  public get currentFilter(): UserGroupFilters {
    return this.get().filter;
  }

  private noFilterApplied(filter: UserGroupFilters): boolean {
    return DataUtil.isEmpty(filter.name) && !filter.imported;
  }
}
