import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { TableCountComponent } from '../table-count/table-count.component';

@Component({
  selector: 'sacc-table-header',
  styleUrl: 'table-header.component.scss',
  templateUrl: 'table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  // Needed to style the dynamically inserted components
  encapsulation: ViewEncapsulation.None,
  imports: [TableCountComponent]
})
export class TableHeaderComponent {
  @HostBinding('class.sacc-table-header') public hostCls = true;

  @Input() public countLabelSingular: string;
  @Input() public countLabelPlural: string;
  @Input() public count: number;
}
