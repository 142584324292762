import { Sort } from '@angular/material/sort';

import { AccountAccessStatus } from '@celum/authentication';

import { AccountMemberRole } from './account-member-role';
import { SortablePaginationOption } from '../sortable-pagination-option';

export enum AccountMemberFilterPrivileges {
  WORK = 'WORK',
  EXPERIENCE = 'EXPERIENCE'
}

export interface SelectedGroupItem {
  id: string;
  name: string;
}

export interface AccountMemberFilters extends SortablePaginationOption {
  nameOrEmail?: string;
  status?: AccountAccessStatus[];
  imported?: boolean;
  privileges?: AccountMemberFilterPrivileges[];
  roles?: AccountMemberRole[];
  groups?: SelectedGroupItem[];
}

export function createEmptyAccountMemberFilter(sort?: Sort): AccountMemberFilters {
  return {
    nameOrEmail: '',
    sort: sort || createDefaultAccountMemberFilterSort()
  };
}

export function createDefaultAccountMemberFilterSort(): Sort {
  return { active: 'createdOn', direction: 'asc' };
}
