<p class="sacc-filter-group-search_header">
  {{ title | translate }}
</p>
<lookup-area
  class="sacc-filter-group-search_input"
  data-test-sacc-filter-group-search-input
  tabindex="0"
  [debounceTime]="300"
  [formFieldSize]="'small'"
  [placeholder]="'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.GROUPS.PLACEHOLDER' | translate"
  [value]="searchTerm()"
  (searchValue)="searchTermChanged($event)"></lookup-area>

@if (displayItems()?.length > 0) {
  <div class="sacc-filter-group-search_result" data-test-sacc-filter-group-search-result>
    @for (displayItem of displayItems(); track displayItem.id) {
      <button
        class="sacc-filter-group-search_result-item"
        data-test-sacc-filter-group-search-result-item
        mat-button
        [disableRipple]="true"
        (click)="toggleSelectedGroup(displayItem)">
        <sacc-group-avatar
          data-test-sacc-filter-group-search-result-item-avatar
          [config]="displayItem | groupToAvatarConfig: AVATAR_SIZE.s"></sacc-group-avatar>
        <mat-checkbox
          class="sacc-filter-group-search_result-item-checkbox"
          [attr.data-test-sacc-filter-group-search-checkbox]="displayItem.id"
          [checked]="displayItem.isChecked"
          [disableRipple]="true"
          [labelPosition]="'before'"
          (change)="toggleSelectedGroup(displayItem)"
          (click)="$event.stopPropagation()">
          <div
            class="sacc-filter-group-search_result-item-text"
            data-test-sacc-filter-group-search-result-item-text
            spaceAwareTooltip
            [matTooltip]="displayItem.name">
            {{ displayItem.name }}
          </div>
        </mat-checkbox>
      </button>
    }
    @if (showSearchMoreItem()) {
      <hr />
      <div class="sacc-filter-group-search_result-special-message" data-test-sacc-filter-group-search-result-load-more>
        {{ 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.SPECIFY_SEARCH' | translate }}
      </div>
    }
  </div>
} @else {
  <div class="sacc-filter-group-search_result-no-result" data-test-sacc-filter-group-search-no-result>
    <celum-icon class="sacc-filter-group-search_result-no-result-icon" [clickable]="false" [configuration]="iconNoGroup"></celum-icon>
    <span class="sacc-filter-group-search_result-special-message">{{ 'COMPONENTS.INVITATION_TABLE.NO_INVITATIONS_FOUND' | translate }}</span>
  </div>
}
