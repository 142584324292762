<sacc-table-count
  class="sacc-table-header_count"
  data-test-sacc-table-header
  [count]="count"
  [label]="countLabelSingular"
  [labels]="countLabelPlural"></sacc-table-count>

<ng-container>
  <ng-content class="sacc-table-header_filter-chips" data-test-sacc-table-header-filter-chips select="advanced-filter-chips"></ng-content>
</ng-container>

<div class="sacc-table-header_sort-and-filter">
  <ng-container>
    <ng-content data-test-sacc-table-header-advanced-filter select="advanced-filter"></ng-content>
  </ng-container>
</div>
