import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';

import { AccountAccessStatus, ExperiencePrivilege, InvitationStatus, UserState, WorkroomPrivilege } from '@celum/authentication';
import { AVATAR_SIZE, CelumDialogModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { CelumDirectivesModule } from '@celum/ng2base';
import { AccountAccess, AccountMember } from '@celum/sacc/domain';
import { invitationActions } from '@celum/sacc/shared';
import { UserAvatarComponent } from '@celum/shared/ui-people';

import { EditInvitationDialogService } from './edit-invitation-dialog.service';
import { IconTextButtonComponent } from '../../icon-text-button/icon-text-button.component';
import { PrivilegeIconComponent, PrivilegeIconType } from '../../privilege-icon/privilege-icon.component';
import {
  AccountMemberStatusSwitcherClickedEventArgs,
  AccountMemberStatusSwitcherComponent
} from '../account-member-status-switcher/account-member-status-switcher.component';
import { GroupSelectorComponent } from '../group-selector/group-selector.component';
import { PrivilegeInfoPopupDialogComponent } from '../privilege-info-popup-dialog/privilege-info-popup-dialog.component';

export interface EditInvitationDialogData {
  editedInvitation: AccountMember;
  accountAccess: AccountAccess;
  currentUser: AccountMember;
}

@Component({
  selector: 'sacc-edit-invitation-dialog',
  templateUrl: './edit-invitation-dialog.component.html',
  styleUrl: './edit-invitation-dialog.component.scss',
  imports: [
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    CelumDialogModule,
    CelumDirectivesModule,
    AccountMemberStatusSwitcherComponent,
    GroupSelectorComponent,
    IconTextButtonComponent,
    PrivilegeIconComponent,
    PrivilegeInfoPopupDialogComponent,
    UserAvatarComponent
  ],
  // Needed to style the layout of the dialog content correctly
  encapsulation: ViewEncapsulation.None,
  providers: [EditInvitationDialogService]
})
export class EditInvitationDialogComponent {
  private editedInvitation: AccountMember;

  protected AVATAR_SIZE = AVATAR_SIZE;
  protected invitationForm: any;

  protected readonly PrivilegeIconType = PrivilegeIconType;
  protected readonly ExperiencePrivilege = ExperiencePrivilege;
  protected readonly WorkroomPrivilege = WorkroomPrivilege;

  protected readonly iconInvitation = new IconConfiguration('invite-thin').withIconSize(80).withColor(ColorConstants.BLUE_GRAY_900);

  @ViewChild('privilegesInfoIcon', { static: false, read: ElementRef }) protected privilegesInfoIcon: ElementRef;

  protected readonly AccountAccessStatus = AccountAccessStatus;

  protected readonly InvitationStatus = InvitationStatus;

  protected readonly UserState = UserState;

  constructor(
    private formBuilder: FormBuilder,
    protected service: EditInvitationDialogService,
    protected dialogRef: MatDialogRef<EditInvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: EditInvitationDialogData,
    actions: Actions
  ) {
    // For now, we map the experience-privilege to our internal enum - in the BE it is stored only with FULL_ACCESS or null
    this.editedInvitation = {
      ...data.editedInvitation,
      privileges: {
        ...data.editedInvitation.privileges,
        experience: data.editedInvitation.privileges.experience === ExperiencePrivilege.FULL_ACCESS ? ExperiencePrivilege.FULL_ACCESS : ExperiencePrivilege.NONE
      }
    };

    this.service.init(this.editedInvitation, data.accountAccess, data.currentUser);

    this.invitationForm = this.formBuilder.nonNullable.group({
      status: this.formBuilder.nonNullable.control<InvitationStatus>(this.editedInvitation.invitationStatus),
      workroomPrivilege: this.formBuilder.nonNullable.control<WorkroomPrivilege>(this.editedInvitation.privileges.work),
      experiencePrivilege: this.formBuilder.nonNullable.control<ExperiencePrivilege>(this.editedInvitation.privileges.experience)
    });

    if ([InvitationStatus.PENDING_APPROVAL, InvitationStatus.REJECTED, InvitationStatus.DISAPPROVED].includes(this.editedInvitation.invitationStatus)) {
      this.invitationForm.get('workroomPrivilege').disable();
      this.invitationForm.get('experiencePrivilege').disable();
    }

    this.invitationForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      if (DataUtil.isEmpty(this.service.getChangedValues(this.invitationForm.value, this.editedInvitation))) {
        this.invitationForm.markAsPristine();
      }

      this.service.patchState({ currentStatus: this.invitationForm.controls.status.value });
    });

    actions.pipe(ofType(invitationActions.approveAccountAccessSuccess, invitationActions.disapproveAccountAccessSuccess)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  protected updateInvitation(): void {
    const changes = this.service.getChangedValues(this.invitationForm.value, this.editedInvitation);
    // Special case permissions: If at least work- or experience-permissions are changed, we need to provide both for the backend
    if (changes.privileges) {
      changes.privileges.experience = changes.privileges.experience || this.editedInvitation.privileges.experience;
      changes.privileges.work = changes.privileges.work || this.editedInvitation.privileges.work;
    }

    if (changes.invitationStatus === InvitationStatus.APPROVED) {
      this.service.approveInvitation({ ...this.editedInvitation, invitationStatus: InvitationStatus.APPROVED });
    } else if (changes.invitationStatus === InvitationStatus.DISAPPROVED) {
      this.service.disapproveInvitation({ ...this.editedInvitation, invitationStatus: InvitationStatus.DISAPPROVED });
    } else {
      this.service.updateInvitation(changes);
    }
  }

  protected onStatusSwitcherClicked(clickedEventArgs: AccountMemberStatusSwitcherClickedEventArgs): void {
    this.service.vm$.pipe(take(1)).subscribe(vm => {
      const currentStatus = this.invitationForm.controls.status.value;
      if (vm.editedInvitation.invitationStatus === InvitationStatus.INVITED && currentStatus === InvitationStatus.INVITED) {
        this.service.patchState({ resendInvitation: true });
        this.invitationForm.markAsDirty();
      } else if (vm.editedInvitation.invitationStatus === InvitationStatus.PENDING_APPROVAL && currentStatus === InvitationStatus.PENDING_APPROVAL) {
        const finalInvitationStatus = clickedEventArgs.primaryClicked ? InvitationStatus.APPROVED : InvitationStatus.DISAPPROVED;
        this.invitationForm.get('status').patchValue(finalInvitationStatus);
        this.invitationForm.markAsDirty();
      } else if (vm.editedInvitation.invitationStatus === InvitationStatus.REJECTED && currentStatus === InvitationStatus.REJECTED) {
        // Trigger a resend, the invitationStatus will be set to invited in the BE
        this.service.patchState({ resendInvitation: true });
        this.invitationForm.markAsDirty();
      } else if (vm.editedInvitation.invitationStatus === InvitationStatus.DISAPPROVED && currentStatus === InvitationStatus.DISAPPROVED) {
        this.invitationForm.get('status').patchValue(InvitationStatus.APPROVED);
        this.invitationForm.markAsDirty();
      }
    });
  }
}
