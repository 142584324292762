import { Component, EventEmitter, Input, Output, signal, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AccountMember } from '@celum/sacc/domain';

import { ActivationStatusComponent } from '../../activation-status/activation-status.component';
import { IconTextButtonComponent } from '../../icon-text-button/icon-text-button.component';
import { InvitationStatusComponent } from '../../invitation-status/invitation-status.component';

export interface AccountMemberStatusSwitcherConfig {
  memberOrInvitation: AccountMember;
  disabled: boolean;
  buttonPrimaryIcon: string;
  buttonPrimaryLabel: string;
  buttonPrimaryTooltip: string;
  buttonSecondaryIcon: string;
  buttonSecondaryLabel: string;
  buttonSecondaryTooltip: string;
  switchedPrimaryTextHeader: string;
  switchedPrimaryText: string;
  switchedSecondaryTextHeader: string;
  switchedSecondaryText: string;
  valueNotYetChanged: boolean;
  isInvitation: boolean;
}

export interface AccountMemberStatusSwitcherClickedEventArgs {
  primaryClicked: boolean;
  secondaryClicked: boolean;
}

@Component({
  selector: 'sacc-account-member-status-switcher',
  templateUrl: './account-member-status-switcher.component.html',
  styleUrl: './account-member-status-switcher.component.scss',
  imports: [TranslateModule, MatTooltipModule, ActivationStatusComponent, IconTextButtonComponent, InvitationStatusComponent],
  // Needed to style the icon-text-button
  encapsulation: ViewEncapsulation.None
})
export class AccountMemberStatusSwitcherComponent {
  protected internalConfig: AccountMemberStatusSwitcherConfig;
  protected showSecondaryButton = signal(false);

  @Input()
  public set config(config: AccountMemberStatusSwitcherConfig) {
    this.internalConfig = config;
    if (config.buttonSecondaryIcon || config.buttonSecondaryLabel || config.buttonSecondaryTooltip) {
      this.showSecondaryButton.set(true);
    }
  }

  @Output()
  public readonly clicked = new EventEmitter<AccountMemberStatusSwitcherClickedEventArgs>();

  protected statusPrimarySwitched = signal(false);
  protected statusSecondarySwitched = signal(false);

  protected onStatusSwitchPrimaryClicked(): void {
    this.statusPrimarySwitched.set(true);
    this.clicked?.emit({ primaryClicked: true, secondaryClicked: false });
  }

  protected onStatusSwitchSecondaryClicked(): void {
    this.statusSecondarySwitched.set(true);
    this.clicked?.emit({ primaryClicked: false, secondaryClicked: true });
  }
}
