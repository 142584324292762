import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { CelumAvatarListModule } from '@celum/common-components';
import { UserGroup } from '@celum/sacc/domain';
import { GroupsToAvatarConfigsPipe } from '@celum/sacc/shared';

import { GroupAvatarComponent } from '../group-avatar/group-avatar.component';

@Component({
  selector: 'sacc-group-avatar-list',
  templateUrl: './group-avatar-list.component.html',
  styleUrl: './group-avatar-list.component.scss',
  imports: [CelumAvatarListModule, GroupsToAvatarConfigsPipe, GroupAvatarComponent],
  // Needed to style the "show more groups"-button
  encapsulation: ViewEncapsulation.None
})
export class GroupAvatarListComponent {
  @HostBinding('class.sacc-group-avatar-list') public hostCls = true;
  @HostBinding('attr.data-test-group-avatar-list') public hostTestAttr = true;

  @Input() public groups: UserGroup[];
}
