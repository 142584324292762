import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

import { DataUtil } from '@celum/core';
import { AccountMemberFilters, createEmptyAccountMemberFilter } from '@celum/sacc/domain';
import { AppState, selectAccountActiveAccountId } from '@celum/sacc/shared';

export type AccountMemberFilterState = {
  filter: AccountMemberFilters;
};

export type AccountMemberFilterViewModel = {
  noFiltersApplied: boolean;
  accountId?: string;
} & AccountMemberFilterState;

@Injectable()
export class AccountMemberFilterService extends ComponentStore<AccountMemberFilterState> {
  public vm$ = this.select(this.state$, this.store$.select(selectAccountActiveAccountId), (state, accountId) => this.createViewModel(state, accountId));

  constructor(private store$: Store<AppState>) {
    super({ filter: createEmptyAccountMemberFilter() });
  }

  private createViewModel(state: AccountMemberFilterState, accountId: string): AccountMemberFilterViewModel {
    return {
      ...state,
      noFiltersApplied: this.noFilterApplied(state.filter),
      accountId
    };
  }

  public setFilter(filter: AccountMemberFilters) {
    this.patchState({ filter });
  }

  public get currentFilter(): AccountMemberFilters {
    return this.get().filter;
  }

  private noFilterApplied(filter: AccountMemberFilters): boolean {
    return (
      DataUtil.isEmpty(filter.nameOrEmail) &&
      DataUtil.isEmpty(filter.status) &&
      DataUtil.isEmpty(filter.groups) &&
      DataUtil.isEmpty(filter.roles) &&
      DataUtil.isEmpty(filter.imported) &&
      DataUtil.isEmpty(filter.privileges)
    );
  }
}
