import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { CelumDialogModule, ColorConstants } from '@celum/common-components';

import { DialogConfig } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'sacc-delete-deactivate-account-member-dialog',
  templateUrl: './delete-deactivate-account-member-dialog.component.html',
  styleUrls: ['./delete-deactivate-account-member-dialog.component.scss'],
  imports: [CommonModule, FormsModule, TranslateModule, MatButtonModule, MatCheckboxModule, MatIconModule, CelumDialogModule]
})
export class DeleteDeactivateAccountMemberDialogComponent {
  public title: string;
  public caption: string;
  public description: string;
  public cancelButtonText: string;
  public confirmationButtonText: string;
  public keepScopes: false;
  public hasDriveLicense$: Observable<boolean>;
  protected readonly ColorConstants = ColorConstants;

  protected type: 'info' | 'warning';

  constructor(
    protected dialogRef: MatDialogRef<DeleteDeactivateAccountMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.title = data.title;
    this.confirmationButtonText = data.confirmationButtonText;
    this.cancelButtonText = data.cancelButtonText;
    this.type = data.type;
    this.description = data.description;
    this.caption = data.caption;
    this.hasDriveLicense$ = data.hasDriveLicense$;
  }

  get dialogConfig(): DialogConfig {
    if (this.type === 'warning') {
      return {
        buttonClass: 'warning-button',
        headlineClass: 'warning-headline',
        headlineImageUrl: '../../../../assets/images/dialogs/warning.svg'
      };
    }
    return {
      buttonClass: 'info-button',
      headlineClass: 'info-headline',
      headlineImageUrl: '../../../../assets/images/dialogs/info.svg'
    };
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.dialogRef.close({ keepScopes: this.keepScopes });
  }
}
