import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Sort } from '@angular/material/sort';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { AccountAccess, AuthService, InvitationStatus, License, LicenseType, UserDetails, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { AccountMember, createDefaultInvitationFilterSort, InvitationFilters } from '@celum/sacc/domain';
import {
  AppState,
  invitationActions,
  MemberShipTableColumns,
  selectAccountActiveAccountId,
  selectInvitationFilteredCount,
  selectInvitationsLoading,
  selectInvitationUserInvitationFilter,
  selectInvitationUserInvitationsAll,
  selectInvitationUserInvitationsAllLoaded,
  selectUserCanEditActiveAccount,
  selectUserLicenseByActiveAccountIdAndLicenseType
} from '@celum/sacc/shared';

export type InvitationTableState = {
  allInvitationsLoaded: boolean;
  invitationsLoading: boolean;
  availableColumns: MemberShipTableColumns[];
  token: string;
  tokenExpiration: number;
  hasWorkroomLicense?: boolean;
  hasExperienceLicense?: boolean;
};

export type InvitationTableViewModel = {
  filter: InvitationFilters;
  invitations: ExtendedAccountMember[];
  invitationCount: number;
  canEdit: boolean;
  displayedColumns: MemberShipTableColumns[];
  currentUser: UserDetails;
  accountAccess: AccountAccess;
} & Omit<InvitationTableState, 'tokenExpiration'>;

export type ExtendedAccountMember = AccountMember & {
  canBeDeleted: boolean;
  canBeRejected: boolean;
  canBeAccepted: boolean;
  canBeResent: boolean;
};

@Injectable()
export class InvitationTableService extends ComponentStore<InvitationTableState> {
  public vm$ = this.createViewModel();

  constructor(
    private store$: Store<AppState>,
    private userService: UserService,
    private authService: AuthService
  ) {
    super({
      availableColumns: [],
      allInvitationsLoaded: undefined,
      invitationsLoading: undefined,
      token: undefined,
      tokenExpiration: undefined
    });

    // Select and store values in the ComponentStore that the component (not the template) needs access to
    combineLatest([this.store$.select(selectInvitationUserInvitationsAllLoaded), this.store$.select(selectInvitationsLoading)])
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(
          this.select(state => state.token),
          this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.WORK)).pipe(map((license: License) => license && license.active)),
          this.store$
            .select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.EXPERIENCE))
            .pipe(map((license: License) => license && license.active))
        ),
        switchMap(([[allInvitationsLoaded, invitationsLoading], token, hasWorkroomLicense, hasExperienceLicense]) => {
          if (!token || this.get().tokenExpiration === undefined || Date.now() > this.get().tokenExpiration) {
            return this.authService.getAuthResult().pipe(
              tap(result =>
                this.patchState({
                  tokenExpiration: result.expiresOn.getTime(),
                  token: result.accessToken
                })
              ),
              map(() => ({
                allInvitationsLoaded,
                invitationsLoading,
                hasWorkroomLicense,
                hasExperienceLicense
              }))
            );
          }

          return of({
            allInvitationsLoaded,
            invitationsLoading,
            hasWorkroomLicense,
            hasExperienceLicense
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe(data =>
        this.patchState({
          allInvitationsLoaded: data.allInvitationsLoaded,
          invitationsLoading: data.invitationsLoading,
          hasWorkroomLicense: data.hasWorkroomLicense,
          hasExperienceLicense: data.hasExperienceLicense
        })
      );
  }

  public get allInvitationsLoaded(): boolean {
    return this.get().allInvitationsLoaded;
  }

  public get invitationsLoading(): boolean {
    return this.get().invitationsLoading;
  }

  public init(availableColumns: MemberShipTableColumns[]): void {
    this.patchState({ availableColumns });
  }

  public filterChanged(invitationFilter: InvitationFilters) {
    this.store$.dispatch(invitationActions.filterChanged({ filter: invitationFilter }));
  }

  public sortChanged(sort: Sort) {
    this.vm$
      .pipe(
        map(vm => vm.filter),
        take(1)
      )
      .subscribe(invitationFilter => {
        const filterCopy = { ...invitationFilter };
        filterCopy.sort = sort.direction !== '' ? sort : createDefaultInvitationFilterSort();
        this.store$.dispatch(invitationActions.filterChanged({ filter: filterCopy }));
      });
  }

  private createViewModel(): Observable<InvitationTableViewModel> {
    const invitations$ = this.store$.select(selectInvitationUserInvitationsAll);
    const filteredInvitationCount$ = this.store$.select(selectInvitationFilteredCount);
    const canEdit$ = this.store$.select(selectUserCanEditActiveAccount);
    const accountId$ = this.store$.select(selectAccountActiveAccountId);
    const currentUser$ = this.userService.currentUser$.pipe(isTruthy());
    const filter$ = this.store$.select(selectInvitationUserInvitationFilter);
    return this.select(
      this.state$,
      invitations$,
      filteredInvitationCount$,
      canEdit$,
      currentUser$,
      accountId$,
      filter$,
      (state, invitations, invitationCount, canEdit, currentUser, accountId, filter) => ({
        ...state,
        invitations: invitations.map(invitation => ({
          ...invitation,
          canBeDeleted: [InvitationStatus.PENDING_APPROVAL, InvitationStatus.INVITED, InvitationStatus.DISAPPROVED, InvitationStatus.REJECTED].includes(
            invitation.invitationStatus
          ),
          canBeRejected: invitation.invitationStatus === InvitationStatus.PENDING_APPROVAL,
          canBeAccepted: invitation.invitationStatus !== InvitationStatus.INVITED && invitation.invitationStatus !== InvitationStatus.REJECTED,
          canBeResent: [InvitationStatus.INVITED, InvitationStatus.REJECTED].includes(invitation.invitationStatus),
          canBeModified: [InvitationStatus.INVITED, InvitationStatus.PENDING_APPROVAL, InvitationStatus.REJECTED, InvitationStatus.DISAPPROVED].includes(
            invitation.invitationStatus
          )
        })),
        invitationCount,
        allInvitationsLoaded: state.allInvitationsLoaded,
        displayedColumns: state.availableColumns,
        canEdit,
        accountAccess: currentUser.accountAccesses.find(access => access.accountId === accountId),
        currentUser,
        filter
      })
    );
  }
}
