import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';

import { loaderActions } from './loader.actions';

@Injectable()
export class LoaderEffects {
  public showLoaderOnNavigationStart$ = createEffect(() =>
    this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationStart),
      map(() => loaderActions.show())
    )
  );

  public hideLoaderOnNavigationEnd$ = createEffect(() =>
    this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationEnd),
      map(() => loaderActions.hide())
    )
  );

  constructor(private router: Router) {}
}
