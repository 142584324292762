import { Sort } from '@angular/material/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { AccountMember, AccountMemberFilters, createEmptyAccountMemberFilter } from '@celum/sacc/domain';

export interface AccountMemberState extends EntityState<AccountMember> {
  loading: boolean;
  sort: Sort;
  continuationToken: string;
  totalCount: number;
  filterCount: number;
  filter: AccountMemberFilters;
}

export const accountMemberAdapter: EntityAdapter<AccountMember> = createEntityAdapter<AccountMember>();

export const initialAccountMemberState: AccountMemberState = accountMemberAdapter.getInitialState({
  sort: null,
  continuationToken: '',
  loading: false,
  totalCount: 0,
  filterCount: 0,
  filter: createEmptyAccountMemberFilter()
});
