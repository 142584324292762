import { AccountAccessStatus } from '@celum/authentication';
import { AccountMemberRole } from '@celum/sacc/domain';

export enum AccountMemberFilterCategory {
  Status,
  Role
}

export type AccountMemberFilterItem = { title: string; typeTitle: string } & (
  | { type: AccountMemberFilterCategory.Status; value: AccountAccessStatus }
  | { type: AccountMemberFilterCategory.Role; value: AccountMemberRole }
);

export const accountMemberFilterItemByValue: Record<AccountAccessStatus | AccountMemberRole, AccountMemberFilterItem> = {
  [AccountAccessStatus.ACTIVE]: {
    title: 'COMPONENTS.ACTIVATION_STATUS.ACTIVE',
    typeTitle: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.TITLE',
    type: AccountMemberFilterCategory.Status,
    value: AccountAccessStatus.ACTIVE
  },
  [AccountAccessStatus.INACTIVE]: {
    title: 'COMPONENTS.ACTIVATION_STATUS.INACTIVE',
    typeTitle: 'COMPONENTS.TABLE.HEADER.FILTERS.SEARCH.TYPES.SUBSCRIPTIONS.TITLE',
    type: AccountMemberFilterCategory.Status,
    value: AccountAccessStatus.INACTIVE
  },
  [AccountAccessStatus.INIT]: {
    title: '',
    typeTitle: '',
    type: AccountMemberFilterCategory.Status,
    value: AccountAccessStatus.INIT
  },
  [AccountMemberRole.MANAGER]: {
    title: 'COMPONENTS.ACCOUNT_MEMBER_TABLE.MEMBER_ROLE.MANAGER',
    typeTitle: 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.ROLE.TITLE',
    type: AccountMemberFilterCategory.Role,
    value: AccountMemberRole.MANAGER
  },
  [AccountMemberRole.MEMBER]: {
    title: 'COMPONENTS.ACCOUNT_MEMBER_TABLE.MEMBER_ROLE.MEMBER',
    typeTitle: 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.ROLE.TITLE',
    type: AccountMemberFilterCategory.Role,
    value: AccountMemberRole.MEMBER
  }
};
