import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { distinctUntilChanged, filter, map, take } from 'rxjs';

import { AccountAccess, AccountUserRole, UserDetails } from '@celum/authentication';
import { CelumEmptyPageModule, CelumIconModule, CelumMessageModule, EmptyPage, EmptyPageConfig, IconConfiguration } from '@celum/common-components';
import { CelumDirectivesModule, CelumPipesModule } from '@celum/ng2base';
import { UserGroup } from '@celum/sacc/domain';
import { GroupToAvatarConfigPipe } from '@celum/sacc/shared';

import { UserGroupsListService } from './user-groups-list.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CreateEditUserGroupDialogComponent } from '../create-edit-user-group-dialog/create-edit-user-group-dialog.component';
import { GroupAvatarComponent } from '../group-avatar/group-avatar.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { TableHeaderComponent } from '../table-header/table-header.component';
import { UserGroupFilterComponent } from './user-group-filter/user-group-filter.component';
import { UserGroupFilterChipsComponent } from './user-group-filter-chips/user-group-filter-chips.component';

@Component({
  selector: 'sacc-user-groups-list',
  templateUrl: './user-groups-list.component.html',
  styleUrls: ['./user-groups-list.component.scss'],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    TranslateModule,

    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,

    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumMessageModule,
    CelumPipesModule,

    GroupAvatarComponent,
    GroupToAvatarConfigPipe,
    IconButtonComponent,
    TableHeaderComponent,
    UserGroupFilterChipsComponent,
    UserGroupFilterComponent
  ],
  providers: [UserGroupsListService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupsListComponent {
  @Output()
  public readonly loadingChanged = new EventEmitter<boolean>();

  @Output()
  public readonly userGroupsCountChanged = new EventEmitter<number>();

  @Output()
  public readonly maxGroupCountChanged = new EventEmitter<number>();

  protected maxGroupCount: number;

  protected scrollContainer = '.inner-drawer-content';

  protected AccountUserRole = AccountUserRole;

  protected allColumns: string[] = ['groupAvatar', 'name', 'imported', 'actions'];

  protected noResultsConfig: EmptyPageConfig = EmptyPage.noActionConfig(
    'empty-user-groups-list',
    'no-group-searchable',
    'COMPONENTS.USER_GROUPS.NO_RESULTS',
    'small',
    148
  );

  protected icons: { [key: string]: IconConfiguration } = {
    menu: new IconConfiguration('option-m').withIconSize(28),
    delete: IconConfiguration.large('remove-l'),
    imported: IconConfiguration.medium('check-circle-m')
  };

  constructor(
    public service: UserGroupsListService,
    public translateService: TranslateService,
    private matDialog: MatDialog
  ) {
    this.service.init(this.allColumns);

    this.service.vm$
      .pipe(
        map(vm => ({
          loading: vm.loading,
          totalCount: vm.userGroupsCount,
          maxGroupCount: vm.maxGroupCount
        })),
        distinctUntilChanged(),
        takeUntilDestroyed()
      )
      .subscribe(({ loading, totalCount, maxGroupCount }) => {
        this.loadingChanged.emit(loading);
        this.userGroupsCountChanged.emit(totalCount);
        this.maxGroupCountChanged.emit(maxGroupCount);

        this.maxGroupCount = maxGroupCount;
      });
  }

  @Input()
  public set isReadonly(isReadonly: boolean) {
    this.service.patchState({ isReadonly });
    if (!isReadonly) {
      this.service.patchState({ displayedColumns: this.allColumns.filter(column => column !== 'imported') });
    }
  }

  @Input()
  public set user(user: UserDetails) {
    this.service.setUser(user);
  }

  @Input()
  public set accountId(accountId: string) {
    this.service.setAccountId(accountId);
  }

  protected sortData(sort: Sort): void {
    if (!sort.active) {
      return;
    }

    this.service.sortChanged(sort);
  }

  protected openGroupDialog(userGroup: UserGroup, accountAccess: AccountAccess, isReadonly: boolean, accountId?: string): void {
    const dialogRef = this.matDialog.open(
      CreateEditUserGroupDialogComponent,
      UserGroupsListComponent.createDialogConfig(accountAccess, userGroup, isReadonly, accountId)
    );
    dialogRef.afterClosed().subscribe(dialogResult => dialogResult && this.service.load());
  }

  protected deleteGroup(userGroup: UserGroup): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      data: {
        title: this.translateService.instant('COMPONENTS.USER_GROUPS.DELETE_GROUP_DIALOG.TITLE'),
        caption: this.translateService.instant('COMPONENTS.USER_GROUPS.DELETE_GROUP_DIALOG.DESCRIPTION', { groupName: userGroup.name }),
        confirmationButtonText: this.translateService.instant('COMPONENTS.USER_GROUPS.DELETE_GROUP_DIALOG.CONFIRM'),
        cancelButtonText: this.translateService.instant('COMPONENTS.USER_GROUPS.DELETE_GROUP_DIALOG.CANCEL'),
        type: 'warning'
      }
    };

    this.matDialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter(shouldDelete => shouldDelete)
      )
      .subscribe(() => this.service.deleteUserGroup(userGroup.id));
  }

  private static createDialogConfig(accountAccess: AccountAccess, userGroup: UserGroup, isReadonly = false, accountId?: string): MatDialogConfig {
    return {
      width: '84.8rem',
      height: '75.4rem',
      restoreFocus: false,
      data: {
        accountAccess,
        existingUserGroup: userGroup,
        isReadonly,
        accountId
      }
    };
  }

  protected userGroupTracker(_: number, userGroup: UserGroup): string {
    return userGroup.id;
  }
}
