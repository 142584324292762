import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';

import {
  AccountMember,
  createDefaultUserGroupFilterSort,
  SaccAccountGroupMember,
  SaccProperties,
  SortablePaginationOption,
  UserGroup,
  UserGroupMembersAddedResponse
} from '@celum/sacc/domain';
import { PaginationOption } from '@celum/shared/domain';
import { Result } from '@celum/shared/util';

import { Utils } from '../utils';

export enum UserGroupMemberAddedError {
  CREATION_FAILED = 555,
  NAME_CONFLICT = 409
}

export interface UserGroupsFindOptions extends SortablePaginationOption {
  ids?: string[];
  filter?: string;
  imported?: boolean;
}

export interface GroupCountResult {
  count: number;
  maxGroupCount: number;
}

export interface GroupMemberCountResult {
  count: number;
  maxGroupMemberCount: number;
}

@Injectable({ providedIn: 'root' })
export class UserGroupResourceService {
  constructor(private http: HttpClient) {}

  public deleteUserGroup(accountId: string, groupId: string): any {
    return this.http.delete<UserGroup>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${groupId}`);
  }

  public createUserGroup(name: string, userIds: string[], accountId: string): any {
    return this.http.post<UserGroup>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups`, { name }).pipe(
      switchMap(userGroup => this.createGroupMemberOperations(userIds, accountId, userGroup.id)),
      switchMap((response: UserGroupMembersAddedResponse[]) => {
        const errors = response?.map(resp => resp.failedAddedMembers).flat();
        if (errors.length > 0) {
          return throwError(() => new HttpErrorResponse({ status: UserGroupMemberAddedError.CREATION_FAILED, error: errors }));
        }

        return of(response);
      })
    );
  }

  public editUserGroup(
    groupId: string,
    name: string,
    idsToAdd: string[],
    idsToRemove: string[],
    accountId: string
  ): Observable<UserGroupMembersAddedResponse[]> {
    return this.http.patch<UserGroup>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${groupId}`, { name }).pipe(
      switchMap(userGroup => this.createGroupMemberOperations(idsToAdd, accountId, userGroup.id, 100, idsToRemove)),
      switchMap((response: UserGroupMembersAddedResponse[]) => {
        const errors = response?.map(resp => resp.failedAddedMembers).flat();
        if (errors.length > 0) {
          return throwError(() => new HttpErrorResponse({ status: UserGroupMemberAddedError.CREATION_FAILED, error: errors }));
        }

        return of(response);
      })
    );
  }

  public searchUserGroupsForAccount(accountId: string, options: Partial<UserGroupsFindOptions> = { offset: 0, limit: 250 }): Observable<Result<UserGroup>> {
    const finalOptions = {
      ...options,
      offset: options?.offset || 0,
      limit: options?.limit || 250,
      sort: `${options?.sort?.active || 'name'},${options?.sort?.direction || 'asc'}`,
      imported: options?.imported || false
    };

    const params = new HttpParams()
      .set('page', finalOptions.offset / finalOptions.limit)
      .set('size', finalOptions.limit)
      .set('sort', finalOptions.sort);

    return this.http
      .post<{
        entities: UserGroup[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(
        `${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/search`,
        { filter: finalOptions.filter, imported: finalOptions.imported },
        { params }
      )
      .pipe(map(result => Utils.mapToResult<UserGroup>(result, finalOptions.offset)));
  }

  public getUserGroupsForAccount(accountId: string, options: Partial<UserGroupsFindOptions> = { offset: 0, limit: 1 }): Observable<Result<UserGroup>> {
    const finalSort = options.sort ? { ...options.sort } : createDefaultUserGroupFilterSort();
    if (finalSort?.active === 'imported') {
      finalSort.active = 'federationGroupId';
    }

    const finalOptions = {
      ...options,
      offset: options?.offset || 0,
      limit: options?.limit || 1
    };

    const params = new HttpParams()
      .set('page', finalOptions.offset / finalOptions.limit)
      .set('size', finalOptions.limit)
      .set('sort', `${finalSort.active},${finalSort.direction}`);

    return this.http
      .post<{
        entities: UserGroup[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(
        `${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/search`,
        { filter: options.filter, ...(finalOptions.imported && { imported: finalOptions.imported }) },
        { params }
      )
      .pipe(map(result => Utils.mapToResult<UserGroup>(result, finalOptions.offset)));
  }

  public getUserGroupCount(accountId: string): Observable<GroupCountResult> {
    return this.http.get<GroupCountResult>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/count`);
  }

  public getUserGroupMembers(accountId: string, userGroupId: string, options: PaginationOption = { offset: 0, limit: 1 }): Observable<Result<AccountMember>> {
    const params = new HttpParams()
      .set('page', options.offset / options.limit)
      .set('size', options.limit)
      .set('sort', '_ts,desc');

    return this.http
      .get<{
        entities: SaccAccountGroupMember[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${userGroupId}/members`, { params })
      .pipe(
        map(result => {
          const mappedUsers: AccountMember[] = result.entities.map(saccAccountGroupMember => ({
            id: saccAccountGroupMember.accountMemberId,
            ...saccAccountGroupMember
          }));
          const mappedResult = {
            totalCount: result.totalCount,
            filterCount: result.filterCount,
            continuationToken: result.continuationToken,
            entities: mappedUsers
          };
          return Utils.mapToResult<AccountMember>(mappedResult, options.offset);
        })
      );
  }

  public getUserGroupMemberCount(accountId: string, groupId: string): Observable<GroupMemberCountResult> {
    return this.http.get<GroupMemberCountResult>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${groupId}/members/count`);
  }

  private createGroupMemberOperations(
    idsToAdd: string[],
    accountId: string,
    userGroupId: string,
    chunkSize = 100,
    idsToRemove?: string[]
  ): Observable<UserGroupMembersAddedResponse[]> {
    if (idsToAdd.length === 0 && (idsToRemove === undefined || idsToRemove.length === 0)) {
      return of([
        {
          failedAddedMembers: []
        }
      ]);
    }

    const operations = [];
    const url = `${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${userGroupId}/members`;

    for (let i = 0; i < idsToAdd.length; i += chunkSize) {
      const chunk = idsToAdd.slice(i, i + chunkSize);
      operations.push(this.http.patch<UserGroupMembersAddedResponse>(url, { idsToAdd: chunk }));
    }

    for (let i = 0; i < idsToRemove?.length; i += chunkSize) {
      const chunk = idsToRemove.slice(i, i + chunkSize);
      operations.push(this.http.patch<UserGroupMembersAddedResponse>(url, { idsToRemove: chunk }));
    }

    return forkJoin(operations);
  }
}
