import { createReducer, on } from '@ngrx/store';

import { accountActions } from './account.actions';
import { accountAdapter, initialAccountState } from './account.state';

export const accountReducer = createReducer(
  initialAccountState,
  on(accountActions.createSuccess, (state, { account }) => accountAdapter.addOne(account, { ...state, totalCount: state.totalCount + 1 })),
  on(accountActions.getOneSuccess, (state, { account }) => accountAdapter.upsertOne(account, state)),
  on(accountActions.fetchBatch, state => ({ ...state, loading: true })),
  on(accountActions.fetchBatchFailure, state => ({ ...state, loading: false })),
  on(accountActions.fetchBatchSuccess, (state, { batch }) => ({
    ...accountAdapter.addMany(batch.entities, state),
    continuationToken: batch.continuationToken,
    totalCount: batch.totalCount,
    filterCount: batch.filterCount,
    loading: false
  })),
  on(accountActions.search, (state, _) => ({
    ...state,
    loading: true,
    continuationToken: state.continuationToken
  })),
  on(accountActions.filterChanged, (state, action) => ({
    ...accountAdapter.removeAll(state),
    loading: true,
    continuationToken: '',
    filter: action.filter
  })),
  on(accountActions.searchFailure, state => ({ ...state, loading: false })),
  on(accountActions.searchSuccess, (state, { batch }) => {
    return {
      ...accountAdapter.addMany(batch.entities, state),
      continuationToken: batch.continuationToken,
      totalCount: batch.totalCount,
      filterCount: batch.filterCount,
      loading: false
    };
  }),
  on(accountActions.resetAccountTableFilter, state => ({ ...state, filter: initialAccountState.filter })),
  on(accountActions.sortChanged, (state, { sort }) => ({ ...state, sort })),
  on(accountActions.resetAccountTable, state => ({ ...initialAccountState, filter: state.filter })),
  on(accountActions.selectedAccountChanged, (state, { accountId }) => ({ ...state, selectedAccountId: accountId }))
);
