@if (vm$ | async; as vm) {
  <div class="sacc-account-member-table">
    <div class="sacc-account-member-table_header">
      <sacc-table-header
        [count]="vm.accountMemberCount"
        [countLabelPlural]="'COMPONENTS.ACCOUNT_MEMBERSHIP_PAGE.COUNTER_APPROVED_PLURAL'"
        [countLabelSingular]="'COMPONENTS.ACCOUNT_MEMBERSHIP_PAGE.COUNTER_APPROVED_SINGULAR'">
        <sacc-account-member-filter-chips
          ngProjectAs="advanced-filter-chips"
          [filter]="vm.filter"
          (filterChanged)="service.filterChanged($event)"></sacc-account-member-filter-chips>
        <sacc-account-member-filter
          ngProjectAs="advanced-filter"
          [filter]="vm.filter"
          (filterChanged)="service.filterChanged($event)"></sacc-account-member-filter>
      </sacc-table-header>
    </div>

    <table
      aria-label="Account member table"
      infiniteScroll
      matSort
      mat-table
      [dataSource]="vm.accountMembers"
      [fromRoot]="true"
      [infiniteScrollContainer]="scrollContainer"
      [trackBy]="accountMemberTracker"
      (matSortChange)="sortData($event)"
      (scrolled)="!vm.allAccountMembersLoaded && !vm.accountMembersLoading && onScroll()">
      <ng-container matColumnDef="profile-picture">
        <th *matHeaderCellDef id="profile-picture-header" mat-header-cell></th>
        <td *matCellDef="let element" mat-cell [ngClass]="element.status">
          <celum-user-avatar [accountAccessToken]="vm.token" [user]="element"></celum-user-avatar>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef id="name-header" mat-header-cell mat-sort-header="name">{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.NAME' | translate }}</th>
        <td *matCellDef="let element" mat-cell spaceAwareTooltip [matTooltip]="element | userName" [ngClass]="element.status">{{ element | userName }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th *matHeaderCellDef id="email-header" mat-header-cell mat-sort-header="email">{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.EMAIL' | translate }}</th>
        <td *matCellDef="let element" mat-cell spaceAwareTooltip [matTooltip]="element.email" [ngClass]="element.status">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef id="status-header" mat-header-cell mat-sort-header="status">{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.STATUS' | translate }}</th>
        <td *matCellDef="let element" mat-cell [ngClass]="element.status">
          <sacc-activation-status [activationStatus]="element.status"></sacc-activation-status>
        </td>
      </ng-container>

      <ng-container matColumnDef="groups">
        <th *matHeaderCellDef id="groups-header" mat-header-cell>{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.GROUPS' | translate }}</th>
        <td *matCellDef="let element" mat-cell [ngClass]="element.status">
          <sacc-group-avatar-list [groups]="element.groups"></sacc-group-avatar-list>
        </td>
      </ng-container>

      <ng-container matColumnDef="account-member-role">
        <th *matHeaderCellDef id="account-member-role-header" mat-header-cell mat-sort-header="memberRole">
          {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.ACCOUNT_MEMBER_ROLE' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell [ngClass]="element.status">
          <ng-container>
            @if (element.role === 'MANAGER') {
              <span>{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.MEMBER_ROLE.MANAGER' | translate }}</span>
            }
            @if (element.role === 'MEMBER') {
              <span>{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.MEMBER_ROLE.MEMBER' | translate }}</span>
            }
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="imported">
        <th *matHeaderCellDef="let element" id="imported-header" mat-header-cell mat-sort-header="imported">
          {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.IMPORTED' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell [ngClass]="element.status">
          @if (element.imported) {
            <celum-icon
              class="sacc-account-member-table_imported-icon"
              [configuration]="icons.imported"
              [matTooltip]="'COMPONENTS.ACCOUNT_MEMBER_TABLE.IMPORTED_TOOLTIP' | translate"></celum-icon>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="privileges">
        <th id="privileges-header" mat-header-cell *matHeaderCellDef>
          {{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.PRIVILEGES.TABLE_HEADER' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell [ngClass]="element.status">
          <div class="sacc-account-member-table_privileges-container">
            @if ((alwaysShowPrivileges || vm.hasExperienceLicense) && element.privileges.experience) {
              <sacc-privilege-icon
                class="sacc-account-member-table_privileges-icon"
                [iconType]="PrivilegeIconType.EXPERIENCE"
                [showIndicator]="element.privileges?.experience === ExperiencePrivilege.FULL_ACCESS"
                [tooltip]="
                  vm.canEdit || alwaysShowPrivileges
                    ? 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS'
                    : 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS_DISABLED'
                "></sacc-privilege-icon>
            }
            @if ((alwaysShowPrivileges || vm.hasWorkroomLicense) && element.privileges.work) {
              <sacc-privilege-icon
                class="sacc-account-member-table_privileges-icon"
                [iconType]="PrivilegeIconType.WORK"
                [showIndicator]="element.privileges?.work === WorkroomPrivilege.CURATOR"
                [tooltip]="
                  vm.canEdit || alwaysShowPrivileges
                    ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON'
                    : 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON_DEACTIVATED'
                "></sacc-privilege-icon>
            }
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef id="actions-header" mat-header-cell></th>
        <td mat-cell *matCellDef="let element">
          <sacc-icon-button
            [iconConfiguration]="icons.menu"
            [matMenuTriggerFor]="menu"
            [matTooltip]="'COMPONENTS.TABLE.TOOLTIPS.MORE_ACTIONS' | translate"
            (click)="$event.stopPropagation()"></sacc-icon-button>
          <mat-menu #menu="matMenu">
            @if (canMemberBeModified(element)) {
              <ng-container>
                @if (element.status === AccountAccessStatus.ACTIVE) {
                  <button
                    class="sacc-account-member-table_button-deactivate"
                    mat-menu-item
                    [disabled]="element.isOwner"
                    [matTooltip]="(element.isOwner ? 'COMPONENTS.ACCOUNT_MEMBER_TABLE.DEACTIVATE_OWNER_TOOLTIP' : null) | translate"
                    (click)="toggleStatus(element)">
                    <celum-icon [configuration]="icons.deactivate"></celum-icon>
                    <span>{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.OPTIONS.DEACTIVATE' | translate }}</span>
                  </button>
                }
                @if (element.status === AccountAccessStatus.INACTIVE) {
                  <button
                    class="sacc-account-member-table_button-activate"
                    mat-menu-item
                    [disabled]="element.deactivatedInFederation"
                    [matTooltip]="'COMPONENTS.ACCOUNT_MEMBER_TABLE.OPTIONS.DELETE_DEACTIVATED_DISABLED_IN_FEDERATION' | translate"
                    [matTooltipDisabled]="element.deactivatedInFederation !== true"
                    (click)="toggleStatus(element)">
                    <celum-icon [configuration]="icons.activate"></celum-icon>
                    <span>{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.OPTIONS.ACTIVATE' | translate }}</span>
                  </button>
                }
                <button
                  mat-menu-item
                  [disabled]="element.isSelf || element.isOwner || element.imported"
                  [matTooltip]="element.imported ? ('COMPONENTS.ACCOUNT_MEMBER_TABLE.OPTIONS.DELETE_DEACTIVATED_PROVISIONED' | translate) : null"
                  (click)="removeAccountMember(element)">
                  <celum-icon [configuration]="icons.delete"></celum-icon>
                  <span>{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.OPTIONS.DELETE' | translate }}</span>
                </button>
              </ng-container>
            }
          </mat-menu>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="vm.displayedColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: vm.displayedColumns"
        mat-row
        [class.sacc-account-member-table_row]="allowEditingAccountMember && vm.canEdit && canMemberBeModified(row)"
        (click)="allowEditingAccountMember && vm.canEdit && canMemberBeModified(row) && rowClicked(row); $event.stopPropagation()"></tr>
    </table>

    @if (vm.accountMembers?.length < 1 && vm.accountMembersLoading === false) {
      <div class="sacc-account-member-table_no-account-members-found">
        <img alt="" src="../../../assets/images/no-users-found.svg" />
        <p>{{ 'COMPONENTS.ACCOUNT_MEMBER_TABLE.NO_ACCOUNT_MEMBERS_FOUND' | translate }}</p>
      </div>
    }

    @if (vm.accountMembersLoading) {
      <div class="sacc-account-member-table_loading">
        <mat-spinner [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
      </div>
    }
  </div>
}
