import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-collapse-button',
  templateUrl: './collapse-button.component.html',
  styleUrls: ['./collapse-button.component.scss'],
  imports: [CommonModule, TranslateModule, MatTooltipModule]
})
export class CollapseButtonComponent {
  @Input()
  public opened: boolean;
  @Output()
  public readonly collapse: EventEmitter<any> = new EventEmitter<any>();
}
