import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';

import { selectRepositoryFilter, selectRepositoryNextBatchParams } from './repository.selectors';
import { ErrorFactory } from '../../error.factory';
import { FederationResourceService } from '../../services/federation-resource.service';
import { RepositoryResourceService } from '../../services/repository-resource.service';
import { AppState } from '../app.state';
import { notificationActions } from '../notification/notification.actions';
import { repositoryActions } from '../repository/repository.actions';

@Injectable()
export class RepositoryEffects {
  public onRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.register),
      mergeMap(action =>
        this.repositoryResourceService.registerRepository(action.repository).pipe(
          map(repository => repositoryActions.registerSuccess({ repository })),
          catchError(error => of(repositoryActions.registerFailure({ error })))
        )
      )
    )
  );

  public onRegisterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.registerSuccess),
      map(() =>
        notificationActions.info({
          message: this.translateService.instant('SERVICES.REPOSITORY.EFFECTS.REGISTRATION_SUCCESS_MSG')
        })
      )
    )
  );

  public onFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.updateFailure, repositoryActions.registerFailure, repositoryActions.deleteFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant(ErrorFactory.getErrorMessage(action.error, this.translateService))
        })
      )
    )
  );

  public onUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.update),
      mergeMap(action =>
        this.repositoryResourceService.updateRepository(action.repository).pipe(
          map(repository => repositoryActions.updateSuccess({ repository })),
          catchError(error => of(repositoryActions.updateFailure({ error })))
        )
      )
    )
  );

  public onUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.updateSuccess),
      map(() =>
        notificationActions.info({
          message: this.translateService.instant('SERVICES.REPOSITORY.EFFECTS.UPDATE_SUCCESS_MSG')
        })
      )
    )
  );

  public onDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.delete),
      mergeMap(action =>
        this.repositoryResourceService.deleteRepository(action.repositoryId).pipe(
          map(repositoryId => repositoryActions.deleteSuccess({ repositoryId })),
          catchError(error => of(repositoryActions.deleteFailure({ error })))
        )
      )
    )
  );

  public onDeleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.deleteSuccess),
      map(() =>
        notificationActions.info({
          message: this.translateService.instant('SERVICES.REPOSITORY.EFFECTS.DELETE_SUCCESS_MSG')
        })
      )
    )
  );

  public onGetOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.getOne),
      mergeMap(action =>
        this.repositoryResourceService.getRepository(action.repositoryId).pipe(
          map(repository => repositoryActions.getOneSuccess({ repository })),
          catchError(error => of(repositoryActions.getOneFailure({ error })))
        )
      )
    )
  );

  public onGetOneFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.getOneFailure),
      map(() =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.REPOSITORY.EFFECTS.FETCH_ONE_FAILED')
        })
      )
    )
  );

  public onSortChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.sortChanged),
      map(() => repositoryActions.fetchBatch({ batchSize: null }))
    )
  );

  public onFetchBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.fetchBatch),
      withLatestFrom(this.store$.select(selectRepositoryNextBatchParams)),
      exhaustMap(([action, batchParams]) =>
        this.repositoryResourceService.fetchBatch(batchParams, action.batchSize).pipe(
          map(batch => repositoryActions.fetchBatchSuccess({ batch })),
          catchError(error => of(repositoryActions.fetchBatchFailure({ error })))
        )
      )
    )
  );

  public fetchBatchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.fetchBatchFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.REPOSITORY.EFFECTS.FETCH_REPOSITORIES_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public onResetTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        repositoryActions.resetRepositoryTable,
        repositoryActions.filterChanged,
        repositoryActions.registerSuccess,
        repositoryActions.updateSuccess,
        repositoryActions.deleteSuccess
      ),
      withLatestFrom(this.store$.select(selectRepositoryFilter)),
      map(([_, searchString]) => repositoryActions.search({ filter: searchString }))
    )
  );

  public search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(repositoryActions.search),
      withLatestFrom(this.store$.select(selectRepositoryNextBatchParams)),
      switchMap(([action, batchParams]) =>
        this.repositoryResourceService.search(action.filter, batchParams).pipe(
          map(batch => repositoryActions.searchSuccess({ batch })),
          catchError(error => of(repositoryActions.searchFailure({ error })))
        )
      )
    )
  );

  public requestSafeRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(repositoryActions.requestSafeRedirect),
        switchMap(action => {
          const email = this.authService.getMsalAccount()?.username;
          const userFederation = this.federationResourceService.getSignInFederation(email);
          return this.repositoryResourceService.checkUrlAndRedirect(action.url, userFederation, action.accountId);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private repositoryResourceService: RepositoryResourceService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private federationResourceService: FederationResourceService,
    private authService: AuthService
  ) {}
}
