import { Component, HostBinding } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sacc-celum-footer',
  templateUrl: './celum-footer.component.html',
  styleUrls: ['./celum-footer.component.scss'],
  imports: [TranslateModule]
})
export class CelumFooterComponent {
  @HostBinding('class.sacc-celum-footer') public hostCls = true;
}
