import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AccountAccessStatus, InvitationStatus } from '@celum/authentication';

import { accountMemberActions } from './account-member.actions';
import { selectAccountMemberFilter, selectAccountMemberNextBatchParams } from './account-member.selectors';
import { ErrorFactory } from '../../error.factory';
import { AccountMemberResourceService } from '../../services/account-member-resource.service';
import { userActions } from '../../store/user/user.actions';
import { selectUserCurrent } from '../../store/user/user.selectors';
import { accountActions } from '../account/account.actions';
import { selectAccountActiveAccountId } from '../account/account.selectors';
import { AppState } from '../app.state';
import { loaderActions } from '../loader/loader.actions';
import { notificationActions } from '../notification/notification.actions';

@Injectable()
export class AccountMemberEffects {
  public showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.updateStatus, accountMemberActions.remove),
      map(() => loaderActions.show())
    )
  );

  public hideLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.updateSuccess, accountMemberActions.updateFailure, accountMemberActions.removeFailure, accountMemberActions.removeSuccess),
      map(() => loaderActions.hide())
    )
  );

  public onAccountSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.selectedAccountChanged),
      switchMap(action => {
        const actions = [];
        if (action.resetFilters) {
          actions.push(accountMemberActions.resetAccountTableFilter());
        }
        if (action.resetTables) {
          actions.push(accountMemberActions.resetAccountTable());
        }
        return from(actions);
      })
    )
  );

  // Reset table state and fetch first batch
  public onResetTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.resetAccountTable, accountMemberActions.filterChanged),
      withLatestFrom(this.store$.select(selectAccountMemberFilter)),
      map(() => accountMemberActions.search({ resetSearch: false }))
    )
  );

  public onSortChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.sortChanged),
      map(() => accountMemberActions.fetchBatch())
    )
  );

  // FIXME
  public onFetchBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.fetchBatch),
      withLatestFrom(this.store$.select(selectAccountMemberNextBatchParams), this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([_, batchParams, accountId]) =>
        this.accountMemberResourceService.fetchBatch(accountId, batchParams).pipe(
          map(batch => accountMemberActions.fetchBatchSuccess({ batch })),
          catchError(error => of(accountMemberActions.fetchBatchFailure({ error })))
        )
      )
    )
  );

  public fetchBatchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.fetchBatchFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_MEMBER.EFFECTS.FETCH_MEMBER_DETAILS_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.search),
      withLatestFrom(
        this.store$.select(selectAccountMemberNextBatchParams),
        this.store$.select(selectAccountActiveAccountId),
        this.store$.select(selectAccountMemberFilter)
      ),
      switchMap(([{ resetSearch }, batchParams, accountId, accountMemberFilter]) => {
        const finalParams = { ...batchParams, sort: accountMemberFilter.sort };
        if (resetSearch) {
          finalParams.continuationToken = '';
        }

        return this.accountMemberResourceService
          .search(accountId, accountMemberFilter, [InvitationStatus.ACCEPTED, InvitationStatus.APPROVED], accountMemberFilter.status, finalParams)
          .pipe(
            map(batch => accountMemberActions.searchSuccess({ batch })),
            catchError(error => of(accountMemberActions.searchFailure({ error })))
          );
      })
    )
  );

  public updateAccountMemberStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.updateStatus),
      withLatestFrom(this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([action, accountId]) =>
        this.accountMemberResourceService
          .updateAccountMember(
            accountId,
            action.accountMember.id,
            { status: action.accountMember.status, ...(action.keepScopes !== undefined && { keepScopes: action.keepScopes }) },
            action.accountMember
          )
          .pipe(
            map(accountMember => accountMemberActions.updateSuccess({ accountMember, wasActivated: accountMember?.status === AccountAccessStatus.ACTIVE })),
            catchError(error => of(accountMemberActions.updateFailure({ error })))
          )
      )
    )
  );

  public updateAccountMemberFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.updateFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCOUNT_MEMBER.UPDATE_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public updateAccountMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.updateSuccess),
      map(params => {
        let messageKey = 'SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCOUNT_MEMBER.UPDATE_SUCCESS';
        if (params.wasActivated !== undefined) {
          messageKey = params.wasActivated
            ? 'SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCOUNT_MEMBER.ACTIVATED_SUCCESS'
            : 'SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCOUNT_MEMBER.DEACTIVATED_SUCCESS';
        }
        return notificationActions.info({
          message: this.translateService.instant(messageKey)
        });
      })
    )
  );

  public reloadUserOnAccountMemberUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.updateSuccess),
      withLatestFrom(this.store$.select(selectUserCurrent)),
      filter(([action, user]) => action.accountMember.email === user.email),
      map(() => userActions.getDetails({ forceReload: true }))
    )
  );

  public onRemoveAccountMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.remove),
      withLatestFrom(this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([action, accountId]) =>
        this.accountMemberResourceService.removeAccountMember(accountId, action.accountMember.id, action.keepScopes).pipe(
          map(accountMemberId => accountMemberActions.removeSuccess({ accountMemberId })),
          catchError(error => of(accountMemberActions.removeFailure({ error })))
        )
      )
    )
  );

  public removeAccountMemberFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.removeFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCOUNT_MEMBER.REMOVAL_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public removeAccountMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountMemberActions.removeSuccess),
      map(() =>
        notificationActions.info({
          message: this.translateService.instant('SERVICES.ACCOUNT_MEMBER.EFFECTS.ACCOUNT_MEMBER.REMOVAL_SUCCESS')
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountMemberResourceService: AccountMemberResourceService,
    private store$: Store<AppState>,
    private translateService: TranslateService
  ) {}
}
