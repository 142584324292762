import { Sort } from '@angular/material/sort';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { InvitationStatus } from '@celum/authentication';
import { AccountMember, BatchDTO, InvitationFilters } from '@celum/sacc/domain';

export const invitationActions = createActionGroup({
  source: '[Invitation]',
  events: {
    'Fetch Batch': emptyProps(),
    'Fetch Batch Success': props<{ batch: BatchDTO<AccountMember> }>(),
    'Fetch Batch Failure': props<{ error: any }>(),
    // prettier-ignore
    'Search': props<{ filter: InvitationFilters, resetSearch?: boolean }>(),
    'Search Success': props<{ batch: BatchDTO<AccountMember> }>(),
    'Search Failure': props<{ error: any }>(),
    'Filter Changed': props<{ filter: InvitationFilters }>(),
    'Delete Invitation Success': props<{ id: string; invitationStatus: InvitationStatus }>(),
    'Resend Invitation Success': props<{ invitation: AccountMember }>(),
    'Reject Invitation': props<{ accountId: string; invitationId: string }>(),
    'Reject Invitation Success': emptyProps(),
    'Reject Invitation Failure': props<{ error: any }>(),
    'Accept Invitation': props<{ accountId: string; invitationId: string }>(),
    'Accept Invitation Success': props<{ accountName: string }>(),
    'Accept Invitation Failure': props<{ error: any }>(),
    'Request Account Access': props<{ accountId: string; repositoryId: string; email: string }>(),
    'Check Repository Account Association': props<{ accountId: string; repositoryId: string }>(),
    'Approve Account Access Success': props<{ accountMember: AccountMember; id: string }>(),
    'Disapprove Account Access Success': props<{ invitation: AccountMember }>(),
    'Request Account Access Via Repository': props<{ repositoryId: string }>(),
    'Request Account Access Via Repository Failure': props<{ error: any }>(),
    'Request Account Access Via Repository Success': props<{ repositoryId: string }>(),
    'Request Repository Associated Account': props<{ repositoryId: string }>(),
    'Sort Changed': props<{ sort: Sort }>(),
    'Reset Account Table': emptyProps(),
    'Reset Account Table Filter': emptyProps(),
    // prettier-ignore
    'Invite': props<{ emails: string[] }>(),
    'Invite Success': props<{ invitations: AccountMember[]; notInvitedUsers: any[] }>(),
    'Invite Failure': props<{ error: any }>(),
    'Execute Account Access Request': props<{ accountId: string; repositoryId: string; email: string }>(),
    'Execute Account Access Request Success': props<{ accountId: string }>(),
    'Execute Account Access Request Failure': props<{ error: any }>(),
    'No Such Associated Account Failure': props<{ error: any }>(),
    'Finish Celum Service Account Access Request As Approved': props<{ accountId: string }>(),
    'Finish Celum Service Account Access Request As Not Yet Approved': props<{ firstRequest: boolean; accountId: string }>()
  }
});
