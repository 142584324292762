import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AccountMemberStatus } from '@celum/sacc/domain';

@Component({
  selector: 'sacc-status',
  templateUrl: `./status.component.html`,
  styleUrls: ['./status.component.scss'],
  imports: [CommonModule, TranslateModule]
})
export class StatusComponent {
  private statusValue: string;

  @Input()
  set status(status: string) {
    this.statusValue = status.toLowerCase();
  }

  get status(): string {
    return this.statusValue;
  }

  get active(): boolean {
    return this.status === AccountMemberStatus.ACTIVE;
  }

  get statusClass(): string {
    return this.status.replace(/ /g, '-');
  }
}
