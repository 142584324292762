import { Sort } from '@angular/material/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { createEmptyFederationFilter, Federation, FederationFilters } from '@celum/sacc/domain';

export interface FederationState extends EntityState<Federation> {
  loading: boolean;
  sort: Sort;
  continuationToken: string;
  totalCount: number;
  filterCount: number;
  filter: FederationFilters;
}

export const federationAdapter: EntityAdapter<Federation> = createEntityAdapter<Federation>({
  selectId: federation => federation.id
});

export const initialFederationState: FederationState = federationAdapter.getInitialState({
  sort: null,
  continuationToken: '',
  loading: false,
  totalCount: 0,
  filterCount: 0,
  filter: createEmptyFederationFilter()
});
