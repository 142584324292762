import { InvitationStatus } from '@celum/authentication';

export enum InvitationFilterCategory {
  Status
}

export type InvitationFilterItem = { title: string; typeTitle: string } & { type: InvitationFilterCategory.Status; value: InvitationStatus };

export const invitationFilterItemByValue: Record<InvitationStatus, InvitationFilterItem> = {
  [InvitationStatus.INVITED]: {
    title: '',
    typeTitle: 'COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: InvitationFilterCategory.Status,
    value: InvitationStatus.INVITED
  },
  [InvitationStatus.ACCEPTED]: {
    title: '',
    typeTitle: 'COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: InvitationFilterCategory.Status,
    value: InvitationStatus.ACCEPTED
  },
  [InvitationStatus.REJECTED]: {
    title: '',
    typeTitle: 'COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: InvitationFilterCategory.Status,
    value: InvitationStatus.REJECTED
  },
  [InvitationStatus.PENDING_APPROVAL]: {
    title: '',
    typeTitle: 'COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: InvitationFilterCategory.Status,
    value: InvitationStatus.PENDING_APPROVAL
  },
  [InvitationStatus.APPROVED]: {
    title: '',
    typeTitle: 'COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: InvitationFilterCategory.Status,
    value: InvitationStatus.APPROVED
  },
  [InvitationStatus.DISAPPROVED]: {
    title: '',
    typeTitle: 'COMPONENTS.INVITATION_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: InvitationFilterCategory.Status,
    value: InvitationStatus.DISAPPROVED
  }
};
