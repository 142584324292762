import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

import { DataUtil } from '@celum/core';
import { createEmptyInvitationFilter, InvitationFilters } from '@celum/sacc/domain';
import { AppState, selectAccountActiveAccountId } from '@celum/sacc/shared';

export type InvitationFilterState = {
  filter: InvitationFilters;
};

export type InvitationFilterViewModel = {
  noFiltersApplied: boolean;
  accountId?: string;
} & InvitationFilterState;

@Injectable()
export class InvitationFilterService extends ComponentStore<InvitationFilterState> {
  public vm$ = this.select(this.state$, this.store$.select(selectAccountActiveAccountId), (state, accountId) => this.createViewModel(state, accountId));

  constructor(private store$: Store<AppState>) {
    super({ filter: createEmptyInvitationFilter() });
  }

  private createViewModel(state: InvitationFilterState, accountId: string): InvitationFilterViewModel {
    return {
      ...state,
      noFiltersApplied: this.noFilterApplied(state.filter),
      accountId
    };
  }

  public setFilter(filter: InvitationFilters) {
    this.patchState({ filter });
  }

  public get currentFilter(): InvitationFilters {
    return this.get().filter;
  }

  private noFilterApplied(filter: InvitationFilters): boolean {
    return DataUtil.isEmpty(filter.memberName) && DataUtil.isEmpty(filter.groups) && DataUtil.isEmpty(filter.status);
  }
}
