import { Sort } from '@angular/material/sort';

import { SortablePaginationOption } from '../sortable-pagination-option';

export interface UserGroupFilters extends SortablePaginationOption {
  name?: string;
  imported?: boolean;
}

export function createEmptyUserGroupFilter(sort?: Sort): UserGroupFilters {
  return {
    name: '',
    sort: sort || createDefaultUserGroupFilterSort()
  };
}

export function createDefaultUserGroupFilterSort(): Sort {
  return { active: 'createdOn', direction: 'asc' };
}
