import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { UUIDGenerator } from '@celum/core';

import { notificationActions } from './notification.actions';

@Injectable()
export class NotificationEffects {
  public info$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.info),
      map(action => new ShowSnackbar(UUIDGenerator.generateId(), SimpleSnackbar, SnackbarConfiguration.success(action.message)))
    )
  );

  public error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.error),
      map(action => new ShowSnackbar(UUIDGenerator.generateId(), SimpleSnackbar, SnackbarConfiguration.error(action.message)))
    )
  );

  constructor(private actions$: Actions) {}
}
