import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { accountActions } from './account.actions';
import { selectAccountActiveAccount, selectAccountFilter, selectAccountNextBatchParams } from './account.selectors';
import { ErrorFactory } from '../../error.factory';
import { LegacyAccountService } from '../../services';
import { AppState } from '../app.state';
import { loaderActions } from '../loader/loader.actions';
import { notificationActions } from '../notification/notification.actions';

@Injectable()
export class AccountEffects {
  public showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.create, accountActions.update, accountActions.uploadLogo, accountActions.updateCompanyDetails),
      map(() => loaderActions.show())
    )
  );

  public hideLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        accountActions.createSuccess,
        accountActions.createFailure,
        accountActions.updateCompanyDetailsSuccess,
        accountActions.updateCompanyDetailsFailure
      ),
      map(() => loaderActions.hide())
    )
  );

  public uploadLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.uploadLogo),
      switchMap(action => of(action).pipe())
    )
  );

  public createAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.create),
      switchMap(action =>
        of(action).pipe(
          switchMap(() => this.accountService.create(action.account)),
          map(account => accountActions.createSuccess({ account })),
          catchError(error => of(accountActions.createFailure({ error })))
        )
      )
    )
  );

  public createAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.createSuccess),
      tap(() => (window.location.href = `${(window as any).Celum.properties.workroomsServiceLinkUrl}`)),
      map(() =>
        notificationActions.info({
          message: this.translateService.instant('SERVICES.ACCOUNT.EFFECTS.ACCOUNT_CREATION_SUCCESS')
        })
      )
    )
  );

  public createAccountFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.createFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT.EFFECTS.ACCOUNT_CREATION_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public onGetAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.getOne),
      mergeMap(action =>
        this.accountService.get(action.id).pipe(
          map(account => accountActions.getOneSuccess({ account })),
          catchError(error => of(accountActions.getOneFailure({ error })))
        )
      )
    )
  );

  public selectedAccountChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.selectedAccountChanged),
      withLatestFrom(this.store$.select(selectAccountActiveAccount)),
      filter(([action, activeAccount]) => !action.skipLoadingDetails && !activeAccount),
      map(([action, _]) => accountActions.getOne({ id: action.accountId }))
    )
  );

  public onSortChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.sortChanged),
      map(_ => accountActions.fetchBatch())
    )
  );

  public onFetchBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.fetchBatch),
      withLatestFrom(this.store$.select(selectAccountNextBatchParams)),
      exhaustMap(([_, batchParams]) =>
        this.accountService.fetchBatch(batchParams).pipe(
          map(batch => accountActions.fetchBatchSuccess({ batch })),
          catchError(error => of(accountActions.fetchBatchFailure({ error })))
        )
      )
    )
  );

  // Reset table state and fetch first batch
  public onResetTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.resetAccountTable, accountActions.filterChanged),
      withLatestFrom(this.store$.select(selectAccountFilter)),
      map(([_, accountFilter]) => accountActions.search({ filter: accountFilter }))
    )
  );

  public onNewWorkRoomsPlanRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.requestNewPlanForWorkrooms),
      switchMap(action =>
        this.accountService.requestNewPlanForWorkRooms(action.accountId, action.workRoomsPlanType).pipe(
          map(() => accountActions.requestNewPlanForWorkroomsSuccess()),
          catchError(error => of(accountActions.requestNewPlanForWorkroomsFailure({ error })))
        )
      )
    )
  );

  public onContentHubDemoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.requestContenthubDemo),
      exhaustMap(action =>
        this.accountService.requestContentHubDemo(action.accountId).pipe(
          map(() => accountActions.requestContenthubDemoSuccess()),
          catchError(error => of(accountActions.requestContenthubDemoFailure({ error })))
        )
      )
    )
  );

  public showMessageOnSubscriptionRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.requestNewPlanForWorkroomsSuccess, accountActions.requestContenthubDemoSuccess),
      map(() => this.translateService.instant('SERVICES.ACCOUNT.EFFECTS.SUBSCRIPTION_REQUEST_SUCCESS')),
      map(message => notificationActions.info({ message }))
    )
  );

  public showMessageOnSubscriptionRequestFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.requestNewPlanForWorkroomsFailure, accountActions.requestContenthubDemoFailure),
      map(() => this.translateService.instant('SERVICES.ACCOUNT.EFFECTS.SUBSCRIPTION_REQUEST_FAILURE')),
      map(message => notificationActions.error({ message }))
    )
  );

  public search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.search),
      withLatestFrom(this.store$.select(selectAccountNextBatchParams), this.store$.select(selectAccountFilter)),
      switchMap(([_, batchParams, accountFilter]) =>
        this.accountService.search(accountFilter, batchParams).pipe(
          map(batch => accountActions.searchSuccess({ batch })),
          catchError(error => of(accountActions.searchFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: LegacyAccountService,
    private store$: Store<AppState>,
    private translateService: TranslateService
  ) {}
}
