import { Component } from '@angular/core';

import { LanguageInitializationService } from '@celum/shared/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false
})
export class AppComponent {
  constructor(private languageInitializationService: LanguageInitializationService) {
    this.languageInitializationService.init();
  }
}
