<celum-chip-set data-test-account-member-filter-chips [showClearAll]="chips().length > 0" (clearAllClicked)="clearAllFilters()">
  <celum-chip
    *ngFor="let chip of chips(); trackBy: trackFn"
    class="sacc-filter-chips_chip"
    closeIcon="cancel-thick"
    data-test-sacc-user-group-filter-chips-chip
    [attr.data-test-sacc-user-group-filter-chips-chip-title]="chip.title | translate"
    [attr.data-test-sacc-user-group-filter-chips-chip-value]="chip.value | translate"
    [prefix]="chip.title | translate"
    [text]="chip.value | translate"
    (remove)="chip.onRemove()"></celum-chip>
</celum-chip-set>
