import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule as MatCheckBoxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AccountAccessStatus } from '@celum/authentication';
import { CelumButtonModule, CelumIconModule, CelumLookupAreaModule, ColorConstants, IconConfiguration, LookupArea } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';
import { AccountFilters, AccountMemberFilters, AccountMemberRole, createEmptyAccountMemberFilter, SelectedGroupItem } from '@celum/sacc/domain';
import { ClickablePopupDialogComponent } from '@celum/shared/ui';

import { AccountMemberFilterCategory, AccountMemberFilterItem, accountMemberFilterItemByValue } from './account-member-filter.item';
import { AccountMemberFilterService } from './account-member-filter.service';
import { ActivationStatusComponent } from '../../activation-status/activation-status.component';
import { FilterGroupSearchComponent } from '../../filter-group-search/filter-group-search.component';

@Component({
  selector: 'sacc-account-member-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCheckBoxModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,

    CelumButtonModule,
    CelumIconModule,
    CelumLookupAreaModule,
    CelumPipesModule,

    ActivationStatusComponent,
    ClickablePopupDialogComponent,
    FilterGroupSearchComponent
  ],
  templateUrl: './account-member-filter.component.html',
  styleUrl: './account-member-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [AccountMemberFilterService]
})
export class AccountMemberFilterComponent {
  @HostBinding('class.sacc-account-member-filter') protected readonly hostClass = true;

  @Output() public readonly filterChanged = new EventEmitter<AccountMemberFilters>();

  @ViewChild('firstSearchInput', { static: false, read: LookupArea }) protected searchInput: LookupArea;

  protected readonly filterSections: Map<string, AccountMemberFilterItem[]> = new Map([
    [
      'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.STATUS.TITLE',
      [accountMemberFilterItemByValue[AccountAccessStatus.ACTIVE], accountMemberFilterItemByValue[AccountAccessStatus.INACTIVE]]
    ],
    [
      'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.ROLE.TITLE',
      [accountMemberFilterItemByValue[AccountMemberRole.MANAGER], accountMemberFilterItemByValue[AccountMemberRole.MEMBER]]
    ]
  ]);

  protected readonly icons = {
    filter: IconConfiguration.small('filter-l').withColor(ColorConstants.SYSTEM_BLACK),
    search: IconConfiguration.medium('search-m').withColor(ColorConstants.BLUE_GRAY_900),
    clear: IconConfiguration.medium('cancel-m').withColor(ColorConstants.BLUE_GRAY_900)
  };

  protected readonly AccountAccessStatus = AccountAccessStatus;

  constructor(protected service: AccountMemberFilterService) {}

  @Input() public set filter(accountFilter: AccountFilters) {
    this.service.setFilter(accountFilter);
  }

  protected clearAllFilters(): void {
    // Clear only the filters, leave sorting as it is!
    this.filterChanged.emit(createEmptyAccountMemberFilter(this.service.currentFilter.sort));
  }

  protected onMenuOpenedChanged(opened: boolean): void {
    if (opened) {
      this.searchInput.focus();
    }
  }

  protected memberNameSearchChanged(nameOrEmail: string): void {
    if (this.service.currentFilter.nameOrEmail !== nameOrEmail) {
      this.filterChanged.emit({ ...this.service.currentFilter, nameOrEmail });
    }
  }

  protected importedChanged(): void {
    this.filterChanged.emit({ ...this.service.currentFilter, imported: !this.service.currentFilter.imported });
  }

  protected isFilterApplied(filter: AccountMemberFilterItem, filters: AccountMemberFilters): boolean {
    return filter.type === AccountMemberFilterCategory.Status ? filters.status?.includes(filter.value) : filters.roles?.includes(filter.value);
  }

  public toggleFilter<T extends AccountAccessStatus | AccountMemberRole>(value: T): void {
    const filterItem = accountMemberFilterItemByValue[value];
    const previous = this.service.currentFilter;
    const typeKey = filterItem.type === AccountMemberFilterCategory.Status ? 'status' : 'roles';
    const previousValue = previous[typeKey] as T[];

    let nextValue = [value];
    if (previousValue) {
      nextValue = previousValue.includes(value) ? previousValue.filter(a => a !== value) : [...previousValue, value];
    }

    this.filterChanged.emit({ ...previous, [typeKey]: nextValue });
  }

  protected onKeyDown(keyboardEvent: KeyboardEvent) {
    if (keyboardEvent.key === 'Tab' || (keyboardEvent.key === 'Tab' && keyboardEvent.shiftKey)) {
      keyboardEvent.stopPropagation();
    }
  }

  protected onSelectedGroupItemsChanged(selectedGroupItems: SelectedGroupItem[]) {
    this.filterChanged.emit({ ...this.service.currentFilter, groups: selectedGroupItems });
  }
}
