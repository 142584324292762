import { Sort } from '@angular/material/sort';

import { InvitationStatus } from '@celum/authentication';

import { SelectedGroupItem } from './account-member-filter';
import { SortablePaginationOption } from '../sortable-pagination-option';

export interface InvitationFilters extends SortablePaginationOption {
  memberName?: string;
  status?: InvitationStatus[];
  groups?: SelectedGroupItem[];
}

export function createEmptyInvitationFilter(sort?: Sort): InvitationFilters {
  return {
    memberName: '',
    sort: sort || createDefaultInvitationFilterSort()
  };
}

export function createDefaultInvitationFilterSort(): Sort {
  return { active: 'createdOn', direction: 'asc' };
}
